/**
 * ympshipyard: Build interface for shipyard
 * magsael-184: Equipment History - Should be able to edit history of equipment
 * * ymploki-197: Build Spare parts interface
 * great-galing-sc-223: Add functionality to delete histoPhoto and files from history
 * ymploki-197: Build Spare parts interface
 * loki-ribeiro-sc-238: save themes for individual users
 * loki-ribero-sc-277: new logsbook setup
 * olain-tarcina-sc-293: show pages in equipment details
 * great-galing-sc-325: Add department to all fields
 * maco-magsael-sc-345: ISM - autosave implementation
 * olain-tarcina-sc-346: Hours of rest print table
 * olain-tarcina-sc-412: Vessel Docs - make expiry dates editable
 * olain-tarcina-sc-425: Signature - Create interface for uploading signatures
 * maco-magsael-sc-458: Calendars - develop calendar module
 * management-module: Create interface for management
 * maco-magsael-sc-485: Equipment - Add annotation for equipment
 * olain-tarcina-sc-543: client details feature
 * maco-magsael-sc-551: Accounting - Create Invoice generator
 * olain-tarcina-sc-479: Notifications module
 * olain-tarcina-sc-588 - Accounting update
 * new-shipyard
 * maco-magsael-sc-643: Shipyard: New shipyard modifications
 * olain-tarcina-sc-639: add order history
 * maco-magsael-sc-663: Shipyard - add files and pinpoint feature
 * maco-magsael-sc-655: Jobs: In Jobdetails when you create Checklist the data you fill up in the Submit Job deleted
 * maco-magsael-sc-673: Inventory - Add Parse csv feature
 * maco-magsael-sc-677: YMP Lite - Interface for lite equipment and jobs
 * olain-tarcina-sc-664: Hours of Rest - print revamp
 * maco-magsael-sc-689: Charter - create charter interface
 * maco-magsael-sc-691: Sales - Create TYS Sales module
 * maco-magsael-sc-692: Payroll - Create payroll module
 * maco-magsael-sc-695: Vessel Docs - changes to certificate
 * olain-tarcina-sc-721: Vessel Docs - add checklist function to documents
 *
 */

export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
//from emilus
export const SHOW_LOADING = 'SHOW_LOADING';
export const SHOW_CLASS_LOADING = 'SHOW_CLASS_LOADING';

export const ACCOUNT_DELETED = 'ACCOUNT_DELETED';

// for Themes reducer
export const TOGGLE_COLLAPSED_NAV = 'TOGGLE_COLLAPSED_NAV';
export const CHANGE_LOCALE = 'CHANGE_LOCALE';
export const SIDE_NAV_STYLE_CHANGE = 'SIDE_NAV_STYLE_CHANGE';
export const NAV_TYPE_CHANGE = 'NAV_TYPE_CHANGE';
export const TOP_NAV_COLOR_CHANGE = 'TOP_NAV_COLOR_CHANGE';
export const HEADER_NAV_COLOR_CHANGE = 'HEADER_NAV_COLOR_CHANGE';
export const TOGGLE_MOBILE_NAV = 'TOGGLE_MOBILE_NAV';
export const SWITCH_THEME = 'SWITCH_THEME';

//ymp
export const GET_WORKLISTS = 'GET_WORKLISTS';
export const SHOW_WORKLIST_LOADING = 'SHOW_WORKLIST_LOADING';
export const WORKLIST_ERROR = 'WORKLIST_ERROR';
export const SHOW_EDIT_WORKLIST_LOADING = 'SHOW_EDIT_WORKLIST_LOADING';
export const GET_EQUIPMENT = 'GET_EQUIPMENT';
export const GET_EQUIPMENT_DETAILS = 'GET_EQUIPMENT_DETAILS';
export const EQUIPMENT_ERROR = 'EQUIPMENT_ERROR';
export const SHOW_EQUIPMENT_LOADING = 'SHOW_EQUIPMENT_LOADING';
export const SHOW_EDIT_EQUIPMENT_LOADING = 'SHOW_EDIT_EQUIPMENT_LOADING';
export const CREATE_WORKLIST_SUCCESS = 'CREATE_WORKLIST_SUCCESS';
export const CREATE_WORKLIST_FAIL = 'CREATE_WORKLIST_FAIL';
export const GET_JOBDETAILS = 'GET_JOBDETAILS';
export const GET_JOBDETAILS_DOCS = 'GET_JOBDETAILS_DOCS';
export const DOCS_ERROR = 'DOCS_ERROR';
export const SHOW_DOCS_LOADING = 'SHOW_DOCS_LOADING';
export const GET_JOBDETAILS_HISTORY = 'GET_JOBDETAILS_HISTORY';
export const HISTORY_ERROR = 'HISTORY_ERROR';
export const SHOW_HISTORY_LOADING = 'SHOW_HISTORY_LOADING';
export const GET_GROUP_HISTORY = 'GET_GROUP_HISTORY';
export const SHOW_GROUP_HISTORY_LOADING = 'SHOW_GROUP_HISTORY_LOADING';
export const GET_LOGBOOKS = 'GET_LOGBOOKS';
export const LOGBOOK_ERROR = 'LOGBOOK_ERROR';
export const SHOW_LOGBOOK_LOADING = 'SHOW_LOGBOOK_LOADING';
export const GET_LOGBOOK_DETAILS = 'GET_LOGBOOK_DETAILS';
export const SHOW_SUBGROUP_LOADING = 'SHOW_SUBGROUP_LOADING';
export const GET_SUBGROUP = 'GET_SUBGROUP';
export const SUBGROUP_ERROR = 'SUBGROUP_ERROR';
export const SHOW_INVENTORY_LOADING = 'SHOW_INVENTORY_LOADING';
export const GET_INVENTORY = 'GET_INVENTORY';
export const INVENTORY_ERROR = 'INVENTORY_ERROR';
export const GET_INVENTORY_DETAILS = 'GET_INVENTORY_DETAILS';
export const SHOW_PROFILE_LOADING = 'SHOW_PROFILE_LOADING';
export const GET_PROFILES = 'GET_PROFILES';
export const PROFILE_ERROR = 'PROFILE_ERROR';
export const GET_PROFILE_DETAILS = 'GET_PROFILE_DETAILS';
export const GET_PROFILE_DOCS = 'GET_PROFILE_DOCS';
export const SHOW_USER_LOADING = 'SHOW_USER_LOADING';
export const GET_USER_DETAILS = 'GET_USER_DETAILS';
export const USER_ERROR = 'USER_ERROR';
export const GET_GUESTS = 'GET_GUESTS';
export const GUESTS_ERROR = 'GUESTS_ERROR';
export const SHOW_GUESTS_LOADING = 'SHOW_GUESTS_LOADING';
export const GET_GUEST_DETAILS = 'GET_GUEST_DETAILS';
export const SHOW_FOLDER_LOADING = 'SHOW_FOLDER_LOADING';
export const SHOW_FILE_LOADING = 'SHOW_FILE_LOADING';
export const GET_FOLDERS = 'GET_FOLDERS';
export const GET_FILES = 'GET_FILES';
// Modification start - 07.11.2022 - olain-tarcina-sc-293
export const GET_PAGES = 'GET_PAGES';
// Modification end - 07.11.2022 - olain-tarcina-sc-293
export const SHOW_BOTH_LOADING = 'SHOW_BOTH_LOADING';
export const EDIT_WORKLIST = 'EDIT_WORKLIST';
export const UPLOAD_MANUAL_SUCCESS = 'UPLOAD_MANUAL_SUCCESS';
export const PHOTOS_ERROR = 'PHOTOS_ERROR';
export const UPLOAD_PHOTO_SUCCESS = 'UPLOAD_PHOTO_SUCCESS';
export const SHOW_PHOTOS_LOADING = 'SHOW_PHOTOS_LOADING';
export const DELETE_PHOTO = 'DELETE_PHOTO';
export const GET_PHOTOS = 'GET_PHOTOS';
export const SUBMIT_JOB_SUCCESS = 'SUBMIT_JOB_SUCCESS';
export const CREATE_EQUIPMENT_SUCCESS = 'CREATE_EQUIPMENT_SUCCESS';
export const EDIT_EQUIPMENT_SUCCESS = 'EDIT_EQUIPMENT_SUCCESS';
export const DELETE_EQUIPMENT = 'DELETE_EQUIPMENT';
export const EDIT_EQUIPMENT_LOGBOOK_SUCCESS = 'EDIT_EQUIPMENT_LOGBOOK_SUCCESS';
export const CREATE_MEASUREMENT_SUCCESS = 'CREATE_MEASUREMENT_SUCCESS';
export const DELETE_MEASUREMENT = 'DELETE_MEASUREMENT';
export const EDIT_MEASUREMENT_LOGBOOK_SUCCESS =
  'EDIT_MEASUREMENT_LOGBOOK_SUCCESS';
export const EDIT_CURRENT_HOURS_SUCCESS = 'EDIT_CURRENT_HOURS_SUCCESS';
export const SHOW_BOAT_LOADING = 'SHOW_BOAT_LOADING';
export const GET_BOATS = 'GET_BOATS';
export const BOAT_ERROR = 'BOAT_ERROR';
export const GET_BOAT_DETAILS = 'GET_BOAT_DETAILS';
export const CREATE_LOGBOOK_SUCCESS = 'CREATE_LOGBOOK_SUCCESS';
export const DELETE_LOGBOOK = 'DELETE_LOGBOOK';
export const DELETE_DOCS = 'DELETE_DOCS';
export const UPLOAD_SUPPORT_SUCCESS = 'UPLOAD_SUPPORT_SUCCESS';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const SHOW_EDIT_PROFILE_LOADING = 'SHOW_EDIT_PROFILE_LOADING';
export const DELETE_USER = 'DELETE_USER';
export const USER_LOADING = 'USER_LOADING';
export const SHOW_EDIT_BOAT_LOADING = 'SHOW_EDIT_BOAT_LOADING';
export const DELETE_BOAT = 'DELETE_BOAT';
export const GET_YMPEQUIPMENT = 'GET_YMPEQUIPMENT';
export const YMPEQUIPMENT_ERROR = 'YMPEQUIPMENT_ERROR';
export const SHOW_YMPEQUIPMENT_LOADING = 'SHOW_YMPEQUIPMENT_LOADING';
export const GET_YMPEQUIPMENT_DETAILS = 'GET_YMPEQUIPMENT_DETAILS';
export const CREATE_YMPEQUIPMENT_SUCCESS = 'CREATE_YMPEQUIPMENT_SUCCESS';
export const SHOW_EDIT_YMPEQUIPMENT_LOADING = 'SHOW_EDIT_YMPEQUIPMENT_LOADING';
export const EDIT_YMPEQUIPMENT_SUCCESS = 'EDIT_YMPEQUIPMENT_SUCCESS';
export const DELETE_YMPEQUIPMENT = 'DELETE_YMPEQUIPMENT';
export const GET_YMPJOBS = 'GET_YMPJOBS';
export const YMPJOBS_ERROR = 'YMPJOBS_ERROR';
export const SHOW_YMPJOBS_LOADING = 'SHOW_YMPJOBS_LOADING';
export const CREATE_YMPJOBS_SUCCESS = 'CREATE_YMPJOBS_SUCCESS';
export const CREATE_YMPJOBS_FAIL = 'CREATE_YMPJOBS_FAIL';
export const GET_YMPJOBDETAILS = 'GET_YMPJOBDETAILS';
export const EDIT_YMPJOBS = 'EDIT_YMPJOBS';
export const SHOW_EDIT_YMPJOB_LOADING = 'SHOW_EDIT_YMPJOB_LOADING';
export const GET_FORMS = 'GET_FORMS';
export const FORMS_ERROR = 'FORMS_ERROR';
export const SHOW_FORMS_LOADING = 'SHOW_FORMS_LOADING';
export const CREATE_FORM_SUCCESS = 'CREATE_FORM_SUCCESS';
export const CREATE_FORM_FAIL = 'CREATE_FORM_FAIL';
export const GET_FORM_DETAILS = 'GET_FORM_DETAILS';
export const EDIT_FORM = 'EDIT_FORM';
export const SHOW_EDIT_FORMS_LOADING = 'SHOW_EDIT_FORMS_LOADING';
export const GET_SUBFORMS = 'GET_SUBFORMS';
export const SUBFORMS_ERROR = 'SUBFORMS_ERROR';
export const SHOW_SUBFORMS_LOADING = 'SHOW_SUBFORMS_LOADING';
export const CREATE_SUBFORM_SUCCESS = 'CREATE_SUBFORM_SUCCESS';
export const CREATE_SUBFORM_FAIL = 'CREATE_SUBFORM_FAIL';
export const GET_SUBFORM_DETAILS = 'GET_SUBFORM_DETAILS';
export const EDIT_SUBFORM = 'EDIT_SUBFORM';
export const SHOW_EDIT_SUBFORMS_LOADING = 'SHOW_EDIT_SUBFORMS_LOADING';
export const DELETE_SUBFORM = 'DELETE_SUBFORM';
export const DELETE_FORM = 'DELETE_FORM';
export const DELETE_MANY_SUBFORMS = 'DELETE_MANY_SUBFORMS';
export const SHOW_LOADING_DONE = 'SHOW_LOADING_DONE';
export const GET_BOATFORMS = 'GET_BOATFORMS';
export const BOATFORMS_ERROR = 'BOATFORMS_ERROR';
export const SHOW_BOATFORMS_LOADING = 'SHOW_BOATFORMS_LOADING';
export const CREATE_BOATFORM_SUCCESS = 'CREATE_BOATFORM_SUCCESS';
export const MIGRATE_SUCCESS = 'MIGRATE_SUCCESS';
export const GET_BOATFORM_DETAILS = 'GET_BOATFORM_DETAILS';
export const DELETE_BOATFORM = 'DELETE_BOATFORM';
export const MIGRATE_YMPEQUIPMENT_SUCCESS = 'MIGRATE_YMPEQUIPMENT_SUCCESS';
export const GET_ALL_FILES = 'GET_ALL_FILES';
export const GET_FOLDERDETAILS = 'GET_FOLDERDETAILS';
export const CREATE_FOLDER_SUCCESS = 'CREATE_FOLDER_SUCCESS';
export const CREATE_FILE_SUCCESS = 'CREATE_FILE_SUCCESS';
export const SHOW_DONE_LOADING = 'SHOW_DONE_LOADING';
export const DELETE_FILES = 'DELETE_FILES';
export const EDIT_FOLDER_SUCCESS = 'EDIT_FOLDER_SUCCESS';
export const SHOW_EDITFOLDER_LOADING = 'SHOW_EDITFOLDER_LOADING';
export const DELETE_FOLDER = 'DELETE_FOLDER';
export const EDIT_FOLDER_PERMISSIONS = 'EDIT_FOLDER_PERMISSIONS';
export const CREATE_GUESTS_SUCCESS = 'CREATE_GUESTS_SUCCESS';
export const GET_EDIT_GUEST_DETAILS = 'GET_EDIT_GUEST_DETAILS';
export const SHOW_EDIT_GUESTS_LOADING = 'SHOW_EDIT_GUESTS_LOADING';
export const DELETE_GUEST = 'DELETE_GUEST';
export const DELETE_WORKLIST = 'DELETE_WORKLIST';
export const SHOW_EDIT_INVENTORY_LOADING = 'SHOW_EDIT_INVENTORY_LOADING';
export const CREATE_INVENTORY = 'CREATE_INVENTORY';
export const EDIT_INVENTORY = 'EDIT_INVENTORY';
export const GET_LOCATION = 'GET_LOCATION';
export const SHOW_LOCATION_LOADING = 'SHOW_LOCATION_LOADING';
export const LOCATION_ERROR = 'LOCATION_ERROR';
export const GET_LOCATION_DETAILS = 'GET_LOCATION_DETAILS';
export const SHOW_EDIT_LOCATION_LOADING = 'SHOW_EDIT_LOCATION_LOADING';
export const CREATE_LOCATION = 'CREATE_LOCATION';
export const EDIT_LOCATION = 'EDIT_LOCATION';
export const DELETE_INVENTORY = 'DELETE_INVENTORY';
export const SHOW_DELETE_INVENTORY_LOADING = 'SHOW_DELETE_INVENTORY_LOADING';
export const DELETE_LOCATION = 'DELETE_LOCATION';
export const GET_INVENTORY_PHOTOS = 'GET_INVENTORY_PHOTOS';
export const UPLOAD_INVETORY_PHOTO_SUCCESS = 'UPLOAD_INVETORY_PHOTO_SUCCESS';
export const GET_SPAREJOBS = 'GET_SPAREJOBS';
export const SPAREJOBS_ERROR = 'SPAREJOBS_ERROR';
export const SHOW_SPAREJOBS_LOADING = 'SHOW_SPAREJOBS_LOADING';
export const CREATE_SPAREJOBS_SUCCESS = 'CREATE_SPAREJOBS_SUCCESS';
export const DELETE_SPAREJOBS = 'DELETE_SPAREJOBS';
export const EDIT_PREVIOUS_SUCCESS = 'EDIT_PREVIOUS_SUCCESS';
export const EDIT_CURRENT_SUCCESS = 'EDIT_CURRENT_SUCCESS';
export const DELETE_YMPJOB = 'DELETE_YMPJOB';
export const EDIT_ONBOARD_SUCCESS = 'EDIT_ONBOARD_SUCCESS';
export const GET_TASKGROUPS = 'GET_TASKGROUPS';
export const TASKGROUPS_ERROR = 'TASKGROUPS_ERROR';
export const SHOW_TASKGROUPS_LOADING = 'SHOW_TASKGROUPS_LOADING';
export const SHOW_EDIT_TASKGROUPS_LOADING = 'SHOW_EDIT_TASKGROUPS_LOADING';
export const GET_TASKGROUP_DETAILS = 'GET_TASKGROUP_DETAILS';
export const CREATE_TASKGROUP_SUCCESS = 'CREATE_TASKGROUP_SUCCESS';
export const EDIT_TASKGROUP = 'EDIT_TASKGROUP';
export const DELETE_TASK = 'DELETE_TASK';
export const DELETE_TASKGROUP = 'DELETE_TASKGROUP';
export const GET_USERS = 'GET_USERS';
export const SHOW_EVENTS_LOADING = 'SHOW_EVENTS_LOADING';
export const GET_EVENTS = 'GET_EVENTS';
export const EVENTS_ERROR = 'EVENTS_ERROR';
export const EDIT_EVENTS = 'EDIT_EVENTS';
export const CREATE_EVENTS_SUCCESS = 'CREATE_EVENTS_SUCCESS';
export const DELETE_EVENTS = 'DELETE_EVENTS';
export const SHOW_WATCHKEEPERS_LOADING = 'SHOW_WATCHKEEPERS_LOADING';
export const GET_WATCHKEEPERS = 'GET_WATCHKEEPERS';
export const WATCHKEEPERS_ERROR = 'WATCHKEEPERS_ERROR';
export const EDIT_WATCHKEEPERS = 'EDIT_WATCHKEEPERS';
export const SHOW_WATCHKEEPLOGS_LOADING = 'SHOW_WATCHKEEPLOGS_LOADING';
export const GET_WATCHKEEPLOGS = 'GET_WATCHKEEPLOGS';
export const WATCHKEEPLOGS_ERROR = 'WATCHKEEPLOGS_ERROR';
export const SHOW_HOURS_LOADING = 'SHOW_HOURS_LOADING';
export const GET_HOURS = 'GET_HOURS';
export const HOURS_ERROR = 'HOURS_ERROR';
export const CREATE_HOURS_SUCCESS = 'CREATE_HOURS_SUCCESS';
export const EDIT_HOURS = 'EDIT_HOURS';
export const DELETE_HOURS = 'DELETE_HOURS';
export const SHOW_VACATIONS_LOADING = 'SHOW_VACATIONS_LOADING';
export const GET_VACATIONS = 'GET_VACATIONS';
export const VACATIONS_ERROR = 'VACATIONS_ERROR';
export const EDIT_VACATIONS = 'EDIT_VACATIONS';
export const CREATE_VACATIONS_SUCCESS = 'CREATE_VACATIONS_SUCCESS';
export const DELETE_VACATIONS = 'DELETE_VACATIONS';
export const GET_BOATSUBFORMS = 'GET_BOATSUBFORMS';
export const BOATSUBFORMS_ERROR = 'BOATSUBFORMS_ERROR';
export const SHOW_BOATSUBFORMS_LOADING = 'SHOW_BOATSUBFORMS_LOADING';
// Modification start 08.09.2022 - ympolain-165
export const EDIT_BOATFORM = 'EDIT_BOATFORM';
export const CREATE_BOATSUBFORM_SUCCESS = 'CREATE_BOATSUBFORM_SUCCESS';
export const EDIT_BOATSUBFORM = 'EDIT_BOATSUBFORM';
export const DELETE_BOATSUBFORM = 'DELETE_BOATSUBFORM';
export const DELETE_MANY_BOATSUBFORMS = 'DELETE_MANY_BOATSUBFORMS';
// Modification end 08.09.2022 - ympolain-165
export const GET_FORMARCHIVES = 'GET_FORMARCHIVES';
export const FORMARCHIVE_ERROR = 'FORMARCHIVE_ERROR';
export const SHOW_FORMARCHIVE_LOADING = 'SHOW_FORMARCHIVE_LOADING';
export const GET_FORMARCHIVE_DETAILS = 'GET_FORMARCHIVE_DETAILS';
export const CREATE_FORMARCHIVE_SUCCESS = 'CREATE_FORMARCHIVE_SUCCESS';
export const DELETE_FORMARCHIVE = 'DELETE_FORMARCHIVE';
export const GET_PROFILES_CREW = 'GET_PROFILES_CREW';
// modification start - 08.31.2022 - ympshipyard
export const GET_TEAM = 'GET_TEAM';
export const SHOW_TEAM_LOADING = 'SHOW_TEAM_LOADING';
export const TEAM_ERROR = 'TEAM_ERROR';
export const GET_TEAM_DETAILS = 'GET_TEAM_DETAILS';
export const SHOW_EDIT_TEAM_LOADING = 'SHOW_EDIT_TEAM_LOADING';
export const CREATE_TEAM = 'CREATE_TEAM';
export const EDIT_TEAM = 'EDIT_TEAM';
export const DELETE_TEAM = 'DELETE_TEAM';
export const GET_DISCUSSION = 'GET_DISCUSSION';
export const SHOW_DISCUSSION_LOADING = 'SHOW_DISCUSSION_LOADING';
export const DISCUSSION_ERROR = 'DISCUSSION_ERROR';
export const GET_DISCUSSION_DETAILS = 'GET_DISCUSSION_DETAILS';
export const SHOW_EDIT_DISCUSSION_LOADING = 'SHOW_EDIT_DISCUSSION_LOADING';
export const CREATE_DISCUSSION = 'CREATE_DISCUSSION';
export const EDIT_DISCUSSION = 'EDIT_DISCUSSION';
export const DELETE_DISCUSSION = 'DELETE_DISCUSSION';
export const COMMENT_LOADING = 'COMMENT_LOADING';
export const ADD_COMMENT = 'ADD_COMMENT';
export const REMOVE_COMMENT = 'REMOVE_COMMENT';
export const GET_DECK = 'GET_DECK';
export const SHOW_DECK_LOADING = 'SHOW_DECK_LOADING';
export const DECK_ERROR = 'DECK_ERROR';
export const GET_DECK_DETAILS = 'GET_DECK_DETAILS';
export const SHOW_EDIT_DECK_LOADING = 'SHOW_EDIT_DECK_LOADING';
export const EDIT_DECK = 'EDIT_DECK';
export const DELETE_DECK = 'DELETE_DECK';
export const CREATE_DECK = 'CREATE_DECK';
export const GET_PROJECT = 'GET_PROJECT';
export const SHOW_PROJECT_LOADING = 'SHOW_PROJECT_LOADING';
export const PROJECT_ERROR = 'PROJECT_ERROR';
export const SHOW_EDIT_PROJECT_LOADING = 'SHOW_EDIT_PROJECT_LOADING';
export const CREATE_PROJECT = 'CREATE_PROJECT';
export const GET_PROJECT_DETAILS = 'GET_PROJECT_DETAILS';
export const DELETE_PROJECT = 'DELETE_PROJECT';
export const EDIT_PROJECT = 'EDIT_PROJECT';
export const GET_ROOM = 'GET_ROOM';
export const SHOW_ROOM_LOADING = 'SHOW_ROOM_LOADING';
export const ROOM_ERROR = 'ROOM_ERROR';
export const GET_ROOM_DETAILS = 'GET_ROOM_DETAILS';
export const SHOW_EDIT_ROOM_LOADING = 'SHOW_EDIT_ROOM_LOADING';
export const EDIT_ROOM = 'EDIT_ROOM';
export const DELETE_ROOM = 'DELETE_ROOM';
export const CREATE_ROOM = 'CREATE_ROOM';
export const GET_DETAILS_PROJECTHISTORY = 'GET_DETAILS_PROJECTHISTORY';
export const PROJECTHISTORY_ERROR = 'PROJECTHISTORY_ERROR';
export const SHOW_PROJECTHISTORY_LOADING = 'SHOW_PROJECTHISTORY_LOADING';
export const PROJECT_DONE = 'PROJECT_DONE';
// modification end - 08.31.2022 - ympshipyard
// modification start - 09.16.2022 - magsael-184
export const EDIT_JOB_HISTORY = 'EDIT_JOB_HISTORY';
// modification end - 09.16.2022 - magsael-184
// modification  start - 09.28.2022 - ymploki-197
export const GET_SPARE = 'GET_SPARE';
export const SHOW_SPARE_LOADING = 'SHOW_SPARE_LOADING';
export const SPARE_ERROR = 'SPARE_ERROR';
export const SHOW_EDIT_SPARE_LOADING = 'SHOW_EDIT_SPARE_LOADING';
export const EDIT_SPARE = 'EDIT_SPARE';
export const DELETE_SPARE = 'DELETE_SPARE';
export const CREATE_SPARE = 'CREATE_SPARE';
export const GET_SPARE_DETAILS = 'GET_SPARE_DETAILS';
export const GET_SPARE_EQUIPMENT = 'GET_SPARE_EQUIPMENT';
export const SHOW_SPARE_EQUIPMENT_LOADING = 'SHOW_SPARE_EQUIPMENT_LOADING';
export const SPARE_EQUIPMENT_ERROR = 'SPARE_EQUIPMENT_ERROR';
export const SHOW_EDIT_SPARE_EQUIPMENT_LOADING =
  'SHOW_EDIT_SPARE_EQUIPMENT_LOADING';
export const EDIT_SPARE_EQUIPMENT = 'EDIT_SPARE_EQUIPMENT';
export const DELETE_SPARE_EQUIPMENT = 'DELETE_SPARE_EQUIPMENT';
export const CREATE_SPARE_EQUIPMENT = 'CREATE_SPARE_EQUIPMENT';
export const GET_SPARE_EQUIPMENT_DETAILS = 'GET_SPARE_EQUIPMENT_DETAILS';
export const GET_SECTION = 'GET_SECTION';
export const SHOW_SECTION_LOADING = 'SHOW_SECTION_LOADING';
export const SECTION_ERROR = 'SECTION_ERROR';
export const SHOW_EDIT_SECTION_LOADING = 'SHOW_EDIT_SECTION_LOADING';
export const EDIT_SECTION = 'EDIT_SECTION';
export const DELETE_SECTION = 'DELETE_SECTION';
export const CREATE_SECTION = 'CREATE_SECTION';
export const GET_SECTION_DETAILS = 'GET_SECTION_DETAILS';
export const GET_ORDER = 'GET_ORDER';
export const SHOW_ORDER_LOADING = 'SHOW_ORDER_LOADING';
export const ORDER_ERROR = 'ORDER_ERROR';
export const SHOW_EDIT_ORDER_LOADING = 'SHOW_EDIT_ORDER_LOADING';
export const EDIT_ORDER = 'EDIT_ORDER';
export const DELETE_ORDER = 'DELETE_ORDER';
export const CREATE_ORDER = 'CREATE_ORDER';
export const GET_ORDER_DETAILS = 'GET_ORDER_DETAILS';
// Modification start - 05.05.2024 - olain-tarcina-sc-639
export const GET_ORDERLIST = 'GET_ORDERLIST';
// Modification end - 05.05.2024 - olain-tarcina-sc-639
export const GET_SUPPLIER = 'GET_SUPPLIER';
export const SHOW_SUPPLIER_LOADING = 'SHOW_SUPPLIER_LOADING';
export const SUPPLIER_ERROR = 'SUPPLIER_ERROR';
export const SHOW_EDIT_SUPPLIER_LOADING = 'SHOW_EDIT_SUPPLIER_LOADING';
export const EDIT_SUPPLIER = 'EDIT_SUPPLIER';
export const DELETE_SUPPLIER = 'DELETE_SUPPLIER';
export const CREATE_SUPPLIER = 'CREATE_SUPPLIER';
export const GET_SUPPLIER_DETAILS = 'GET_SUPPLIER_DETAILS';
// modification  end - 09.28.2022 - ymploki-197
// modification start - 10.17.2022 - great-galing-sc-223
export const EDIT_SOLO_HISTORY = 'EDIT_SOLO_HISTORY';
// modification end - 10.17.2022 - great-galing-sc-223
// modification start - 10.11.2022 - loki-ribeiro-sc-238
export const GET_THEME_DETAILS = 'GET_THEME_DETAILS';
export const THEME_ERROR = 'THEME_ERROR';
// modification end - 10.11.2022 - loki-ribeiro-sc-238
// modification start - 10.31.2022 - loki-ribero-sc-277
export const GET_LOGS = 'GET_LOGS';
export const LOG_ERROR = 'LOG_ERROR';
export const SHOW_LOG_LOADING = 'SHOW_LOG_LOADING';
export const SHOW_EDIT_LOG_LOADING = 'SHOW_EDIT_LOG_LOADING';
export const GET_LOG_DETAILS = 'GET_LOG_DETAILS';
export const CREATE_LOG = 'CREATE_LOG';
export const EDIT_LOG = 'EDIT_LOG';
export const DELETE_LOG = 'DELETE_LOG';
export const DELETE_LOG_RECORDS = 'DELETE_LOG_RECORDS';
export const GET_LOGRECORD = 'GET_LOGRECORD';
export const LOGRECORD_ERROR = 'LOGRECORD_ERROR';
export const SHOW_LOGRECORD_LOADING = 'SHOW_LOGRECORD_LOADING';
export const SHOW_EDIT_LOGRECORD_LOADING = 'SHOW_EDIT_LOGRECORD_LOADING';
export const CREATE_LOGRECORD = 'CREATE_LOGRECORD';
export const EDIT_LOGRECORD = 'EDIT_LOGRECORD';
export const DELETE_LOGRECORD = 'DELETE_LOGRECORD';
export const GET_LOGRECORD_DETAILS = 'GET_LOGRECORD_DETAILS';
// modification end - 10.31.2022 - loki-ribero-sc-277
// modification start - 12.16.2022 - loki-ribero-sc-277
export const GET_EXPENSES = 'GET_EXPENSES';
export const EXPENSE_ERROR = 'EXPENSE_ERROR';
export const SHOW_EXPENSE_LOADING = 'SHOW_EXPENSE_LOADING';
export const SHOW_EDIT_EXPENSE_LOADING = 'SHOW_EDIT_EXPENSE_LOADING';
export const GET_EXPENSE_DETAILS = 'GET_EXPENSE_DETAILS';
export const CREATE_EXPENSE = 'CREATE_EXPENSE';
export const EDIT_EXPENSE = 'EDIT_EXPENSE';
export const DELETE_EXPENSE = 'DELETE_EXPENSE';
export const DUPLICATE_EXPENSE = 'DUPLICATE_EXPENSE';
export const GET_MERCHANTS = 'GET_MERCHANTS';
export const MERCHANT_ERROR = 'MERCHANT_ERROR';
export const SHOW_MERCHANT_LOADING = 'SHOW_MERCHANT_LOADING';
export const SHOW_EDIT_MERCHANT_LOADING = 'SHOW_EDIT_MERCHANT_LOADING';
export const GET_MERCHANT_DETAILS = 'GET_MERCHANT_DETAILS';
export const CREATE_MERCHANT = 'CREATE_MERCHANT';
export const EDIT_MERCHANT = 'EDIT_MERCHANT';
export const DELETE_MERCHANT = 'DELETE_MERCHANT';
export const GET_CATEGORIES = 'GET_CATEGORIES';
export const CATEGORY_ERROR = 'CATEGORY_ERROR';
export const SHOW_CATEGORY_LOADING = 'SHOW_CATEGORY_LOADING';
export const SHOW_EDIT_CATEGORY_LOADING = 'SHOW_EDIT_CATEGORY_LOADING';
export const GET_CATEGORY_DETAILS = 'GET_MERCHANT_DETAILS';
export const CREATE_CATEGORY = 'CREATE_CATEGORY';
export const EDIT_CATEGORY = 'EDIT_CATEGORY';
export const DELETE_CATEGORY = 'DELETE_CATEGORY';
// modification end - 12.16.2022 - loki-ribero-sc-277
// modification start - 02.06.2023 - great-galing-sc-325
export const GET_SUBCATEGORIES = 'GET_SUBCATEGORIES';
export const SUBCATEGORY_ERROR = 'SUBCATEGORY_ERROR';
export const SHOW_SUBCATEGORY_LOADING = 'SHOW_SUBCATEGORY_LOADING';
export const SHOW_EDIT_SUBCATEGORY_LOADING = 'SHOW_EDIT_SUBCATEGORY_LOADING';
export const GET_SUBCATEGORY_DETAILS = 'GET_MERCHANT_DETAILS';
export const CREATE_SUBCATEGORY = 'CREATE_SUBCATEGORY';
export const EDIT_SUBCATEGORY = 'EDIT_SUBCATEGORY';
export const DELETE_SUBCATEGORY = 'DELETE_SUBCATEGORY';
// modification end - 02.06.2023 - great-galing-sc-325
export const MORE_JOB_DETAILS_HISTORY = 'MORE_JOB_DETAILS_HISTORY';
export const GET_MORE_INVENTORY = 'GET_MORE_INVENTORY';
// modification start - 02.23.2023 - maco-magsael-sc-345
export const EDIT_FORMARCHIVE = 'EDIT_FORMARCHIVE';
// modification end - 02.23.2023 - maco-magsael-sc-345
// modification start - 03.07.2023 - maco-magsael-sc-345
export const SHOW_EDIT_FORMARCHIVE_LOADING = 'SHOW_EDIT_FORMARCHIVE_LOADING';
// modification end - 03.07.2023 - maco-magsael-sc-345
// Modification start - 03.03.2023 - olain-tarcina-sc-346
export const GET_PRINT_HOURS = 'GET_PRINT_HOURS';
// Modification end - 03.03.2023 - olain-tarcina-sc-346
// Modification start - 07.03.2023 - olain-tarcina-sc-361
export const SEARCH_JOB_DETAILS_HISTORY = 'SEARCH_JOB_DETAILS_HISTORY';
// Modification end - 07.03.2023 - olain-tarcina-sc-361
export const SEARCH_INVENTORY = 'SEARCH_INVENTORY';
// Modification start - 05.08.2023 - olain-tarcina-sc-412
export const EDIT_CERTIFICATE = 'EDIT_CERTIFICATE';
// Modification end - 05.08.2023 - olain-tarcina-sc-412
// Modification start - 05.22.2023 - olain-tarcina-sc-425
export const UPDATE_PROFILE_SIGNATURE = 'UPDATE_PROFILE_SIGNATURE';
// Modification end - 05.22.2023 - olain-tarcina-sc-425
// Modification start - 03.07.2023 - olain-tarcina-sc-451
export const GET_SALES = 'GET_SALES';
export const GET_SALES_DETAILS = 'GET_SALES_DETAILS';
export const CREATE_SALES = 'CREATE_SALES';
export const DELETE_SALES = 'DELETE_SALES';
export const DELETE_CLIENT = 'DELETE_CLIENT';
export const SALES_LOADING = 'SALES_LOADING';
export const EDIT_SALES = 'EDIT_SALES';
export const SHOW_SALES_LOADING = 'SHOW_SALES_LOADING';
export const SALES_ERROR = 'SALES_ERROR';
export const GET_YACHTS = 'GET_YACHTS';
export const CREATE_YACHTS = 'CREATE_YACHTS';
export const YACHTS_LOADING = 'YACHTS_LOADING';
export const DELETE_YACHT = 'DELETE_YACHT';
export const YACHT_ERROR = 'YACHT_ERROR';
// Modification end - 12.07.2023 - olain-tarcina-sc-451
// Modification start - 16.11.2023 - olain-tarcina-sc-543
export const GET_CLIENTS = 'GET_CLIENTS';
// Modification end - 16.11.2023 - olain-tarcina-sc-543

// modification start - 08.04.2023 - maco-magsael-sc-458
export const SHOW_CALENDAR_LOADING = 'SHOW_CALENDAR_LOADING';
export const GET_CALENDAR = 'GET_CALENDAR';
export const CALENDAR_ERROR = 'CALENDAR_ERROR';
export const EDIT_CALENDAR = 'EDIT_CALENDAR';
export const CREATE_CALENDAR_SUCCESS = 'CREATE_CALENDAR_SUCCESS';
export const DELETE_CALENDAR = 'DELETE_CALENDAR';
// modification end - 08.04.2023 - maco-magsael-sc-458

// Modification start - 07.30.2023 - management-module
export const SHOW_MANAGEMENT_LOADING = 'SHOW_MANAGEMENT_LOADING';
export const GET_MANAGEMENT_DETAILS = 'GET_MANAGEMENT_DETAILS';
export const MANAGEMENT_ERROR = 'MANAGEMENT_ERROR';
export const GET_MANAGEMENTS = 'GET_MANAGEMENTS';
export const SHOW_EDIT_MANAGEMENT_LOADING = 'SHOW_EDIT_MANAGEMENT_LOADING';
export const DELETE_MANAGEMENT = 'DELETE_MANAGEMENT';
export const CREATE_MANAGEMENT = 'CREATE_MANAGEMENT';
export const EDIT_MANAGEMENT = 'EDIT_MANAGEMENT';
// Modification end - 07.30.2023 - management-module
export const GET_YACHTMAKE = 'GET_YACHTMAKE';
export const CREATE_YACHTSMAKE = 'CREATE_YACHTSMAKE';
export const YACHTMAKE_LOADING = 'YACHTMAKE_LOADING';
export const DELETE_YACHTMAKE = 'DELETE_YACHTMAKE';
export const YACHTMAKE_ERROR = 'YACHTMAKE_ERROR';

// Modification start - 09.20.2023 - maco-magsael-sc-485
export const SHOW_PART_LOADING = 'SHOW_PART_LOADING';
export const GET_PART = 'GET_PART';
export const PART_ERROR = 'PART_ERROR';
export const EDIT_PART = 'EDIT_PART';
export const CREATE_PART_SUCCESS = 'CREATE_PART_SUCCESS';
export const DELETE_PART = 'DELETE_PART';
// Modification end - 09.20.2023 - maco-magsael-sc-485

export const SHOW_PROFILE = 'SHOW_PROFILE';
// modification start - 12.07.2023 - maco-magsael-sc-551
export const GET_INVOICE = 'GET_INVOICE';
export const INVOICE_ERROR = 'INVOICE_ERROR';
export const SHOW_INVOICE_LOADING = 'SHOW_INVOICE_LOADING';
export const SHOW_EDIT_INVOICE_LOADING = 'SHOW_EDIT_INVOICE_LOADING';
export const GET_INVOICE_DETAILS = 'GET_INVOICE_DETAILS';
export const CREATE_INVOICE = 'CREATE_INVOICE';
export const EDIT_INVOICE = 'EDIT_INVOICE';
export const DELETE_INVOICE = 'DELETE_INVOICE';
export const DUPLICATE_INVOICE = 'DUPLICATE_INVOICE';
// modification end - 12.07.2023 - maco-magsael-sc-551
// Modification start - 01.03.2023 - olain-tarcina-sc-588
export const DRAFT_INVOICE = 'DRAFT_INVOICE';
// Modification end - 01.03.2023 - olain-tarcina-sc-588

// Modification start - 25.09.2023 - olain-tarcina-sc-479
export const SHOW_NOTIFICATIONS_LOADING = 'SHOW_NOTIFICATIONS_LOADING';
export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const SEND_NOTIFICATIONS = 'SEND_NOTIFICATIONS';
export const NOTIFICATION_ERROR = 'NOTIFICATION_ERROR';
// Modification end - 25.09.2023 - olain-tarcina-sc-479

// Modification start - 04.24.2024 - new-shipyard
export const GET_SHIPYARD = 'GET_SHIPYARD';
export const SHIPYARD_ERROR = 'SHIPYARD_ERROR';
export const SHOW_SHIPYARD_LOADING = 'SHOW_SHIPYARD_LOADING';
export const SHOW_EDIT_SHIPYARD_LOADING = 'SHOW_EDIT_SHIPYARD_LOADING';
export const SHOW_USER_SHIPYARD_LOADING = 'SHOW_USER_SHIPYARD_LOADING';
export const SHOW_PROGRESS_SHIPYARD_LOADING = 'SHOW_PROGRESS_SHIPYARD_LOADING';
export const GET_SHIPYARD_DETAILS = 'GET_SHIPYARD_DETAILS';
export const CREATE_SHIPYARD = 'CREATE_SHIPYARD';
export const EDIT_SHIPYARD = 'EDIT_SHIPYARD';
export const SHIPYARD_DRAFT_JOB = 'SHIPYARD_DRAFT_JOB';
export const DELETE_SHIPYARD = 'DELETE_SHIPYARD';

export const GET_SHIPYARDHISTORY = 'GET_SHIPYARDHISTORY';
export const SHIPYARDHISTORY_ERROR = 'SHIPYARDHISTORY_ERROR';
export const SHOW_SHIPYARDHISTORY_LOADING = 'SHOW_SHIPYARDHISTORY_LOADING';
export const SHOW_EDIT_SHIPYARDHISTORY_LOADING =
  'SHOW_EDIT_SHIPYARDHISTORY_LOADING';
export const GET_SHIPYARDHISTORY_DETAILS = 'GET_SHIPYARDHISTORY_DETAILS';
export const CREATE_SHIPYARDHISTORY = 'CREATE_SHIPYARDHISTORY';
export const EDIT_SHIPYARDHISTORY = 'EDIT_SHIPYARDHISTORY';
export const DELETE_SHIPYARDHISTORY = 'DELETE_SHIPYARDHISTORY';

export const GET_SHIPYARDDOC = 'GET_SHIPYARDDOC';
export const SHIPYARDDOC_ERROR = 'SHIPYARDDOC_ERROR';
export const SHOW_SHIPYARDDOC_LOADING = 'SHOW_SHIPYARDDOC_LOADING';
export const SHOW_EDIT_SHIPYARDDOC_LOADING = 'SHOW_EDIT_SHIPYARDDOC_LOADING';
export const GET_SHIPYARDDOC_DETAILS = 'GET_SHIPYARDDOC_DETAILS';
export const CREATE_SHIPYARDDOC = 'CREATE_SHIPYARDDOC';
export const EDIT_SHIPYARDDOC = 'EDIT_SHIPYARDDOC';
export const DELETE_SHIPYARDDOC = 'DELETE_SHIPYARDDOC';
// Modification end - 04.24.2024 - new-shipyard

// modification start - 05.24.2024 - maco-magsael-sc-643
export const GET_SHIPYARDJOBS = 'GET_SHIPYARDJOBS';
export const SHOW_SHIPYARDJOB_LOADING = 'SHOW_SHIPYARDJOB_LOADING';
export const CREATE_SHIPYARDJOB = 'CREATE_SHIPYARDJOB';
// modification end - 05.24.2024 - maco-magsael-sc-643

// Modification start - 07.18.2024 - nmaco-magsael-sc-663
export const SHOW_PLAN_LOADING = 'SHOW_PLAN_LOADING';
export const GET_PLAN = 'GET_PLAN';
export const PLAN_ERROR = 'PLAN_ERROR';
export const EDIT_PLAN = 'EDIT_PLAN';
export const CREATE_PLAN_SUCCESS = 'CREATE_PLAN_SUCCESS';
export const DELETE_PLAN = 'DELETE_PLAN';
// Modification end - 07.18.2024 - nmaco-magsael-sc-663
// modification start - 07.02.2024 - maco-magsael-sc-655
export const GET_WORKLIST_TASKS = 'GET_WORKLIST_TASKS';
// modification end - 07.02.2024 - maco-magsael-sc-655
// Modification start - 08.08.2024 - maco-magsael-sc-673
export const UPLOAD_INVENTORY_CSV = 'UPLOAD_INVENTORY_CSV';
// Modification end - 08.08.2024 - maco-magsael-sc-673

// Modification start - 19.08.2024 - olain-tarcina-sc-664
export const GET_TIMESHEETS = 'GET_TIMESHEETS';
export const SHOW_TIMESHEETS_LOADING = 'SHOW_TIMESHEETS_LOADING';
export const GET_TIMESHEET_DETAILS = 'GET_TIMESHEET_DETAILS';
export const CREATE_TIMESHEET = 'CREATE_TIMESHEET';
export const UPDATE_TIMESHEET = 'UPDATE_TIMESHEET';
export const DELETE_TIMESHEET = 'DELETE_TIMESHEET';
export const ERROR_TIMESHEET = 'ERROR_TIMESHEET';
// Modification end - 19.08.2024 - olain-tarcina-sc-664

// Modification start - 08.22.2024 - nmaco-magsael-sc-677
export const GET_LITEYMPEQUIPMENT = 'GET_LITEYMPEQUIPMENT';
export const LITEYMPEQUIPMENT_ERROR = 'LITEYMPEQUIPMENT_ERROR';
export const SHOW_LITEYMPEQUIPMENT_LOADING = 'SHOW_LITEYMPEQUIPMENT_LOADING';
export const GET_LITEYMPEQUIPMENT_DETAILS = 'GET_LITEYMPEQUIPMENT_DETAILS';
export const CREATE_LITEYMPEQUIPMENT_SUCCESS =
  'CREATE_LITEYMPEQUIPMENT_SUCCESS';
export const SHOW_EDIT_LITEYMPEQUIPMENT_LOADING =
  'SHOW_EDIT_LITEYMPEQUIPMENT_LOADING';
export const EDIT_LITEYMPEQUIPMENT_SUCCESS = 'EDIT_LITEYMPEQUIPMENT_SUCCESS';
export const DELETE_LITEYMPEQUIPMENT = 'DELETE_LITEYMPEQUIPMENT';
export const MIGRATE_LITEYMPEQUIPMENT_SUCCESS =
  'MIGRATE_LITEYMPEQUIPMENT_SUCCESS';

export const GET_LITEYMPJOB = 'GET_LITEYMPJOB';
export const LITEYMPJOB_ERROR = 'LITEYMPJOB_ERROR';
export const SHOW_LITEYMPJOB_LOADING = 'SHOW_LITEYMPJOB_LOADING';
export const GET_LITEYMPJOB_DETAILS = 'GET_LITEYMPJOB_DETAILS';
export const CREATE_LITEYMPJOB_SUCCESS = 'CREATE_LITEYMPJOB_SUCCESS';
export const SHOW_EDIT_LITEYMPJOB_LOADING = 'SHOW_EDIT_LITEYMPJOB_LOADING';
export const EDIT_LITEYMPJOB_SUCCESS = 'EDIT_LITEYMPJOB_SUCCESS';
export const DELETE_LITEYMPJOB = 'DELETE_LITEYMPJOB';
export const MIGRATE_LITEYMPJOB_SUCCESS = 'MIGRATE_LITEYMPJOB_SUCCESS';

export const SHOW_YMPLITEBOAT_LOADING = 'SHOW_YMPLITEBOAT_LOADING';
export const GET_YMPLITEBOAT_DETAILS = 'GET_YMPLITEBOAT_DETAILS';
export const YMPLITEBOAT_ERROR = 'YMPLITEBOAT_ERROR';
export const GET_YMPLITEBOATS = 'GET_YMPLITEBOATS';
export const SHOW_EDIT_YMPLITEBOAT_LOADING = 'SHOW_EDIT_YMPLITEBOAT_LOADING';
export const DELETE_YMPLITEBOATS = 'DELETE_YMPLITEBOATS';

export const GET_LITE_EQUIPMENT = 'GET_LITE_EQUIPMENT';
export const LITE_EQUIPMENT_ERROR = 'LITE_EQUIPMENT_ERROR';
export const SHOW_LITE_EQUIPMENT_LOADING = 'SHOW_LITE_EQUIPMENT_LOADING';
export const GET_LITE_EQUIPMENT_DETAILS = 'GET_LITE_EQUIPMENT_DETAILS';
export const CREATE_LITE_EQUIPMENT_SUCCESS = 'CREATE_LITE_EQUIPMENT_SUCCESS';
export const SHOW_EDIT_LITE_EQUIPMENT_LOADING =
  'SHOW_EDIT_LITE_EQUIPMENT_LOADING';
export const EDIT_LITE_EQUIPMENT_SUCCESS = 'EDIT_LITE_EQUIPMENT_SUCCESS';
export const EDIT_LITE_EQUIPMENT_LOGBOOK_SUCCESS =
  'EDIT_LITE_EQUIPMENT_LOGBOOK_SUCCESS';
export const DELETE_LITE_EQUIPMENT = 'DELETE_LITE_EQUIPMENT';

export const GET_LITE_WORKLISTS = 'GET_LITE_WORKLISTS';
export const SHOW_LITE_WORKLIST_LOADING = 'SHOW_LITE_WORKLIST_LOADING';
export const LITE_WORKLIST_ERROR = 'LITE_WORKLIST_ERROR';
export const SHOW_EDIT_LITE_WORKLIST_LOADING =
  'SHOW_EDIT_LITE_WORKLIST_LOADING';
export const CREATE_LITE_WORKLIST_SUCCESS = 'CREATE_LITE_WORKLIST_SUCCESS';
export const CREATE_LITE_WORKLIST_FAIL = 'CREATE_LITE_WORKLIST_FAIL';
export const GET_LITE_JOBDETAILS = 'GET_LITE_JOBDETAILS';
export const DELETE_LITE_WORKLIST = 'DELETE_LITE_WORKLIST';

// Modification end - 08.22.2024 - nmaco-magsael-sc-677

// Modification start - 09.22.2024 - maco-magsael-sc-689
export const GET_CHARTER = 'GET_CHARTER';
export const CHARTER_ERROR = 'CHARTER_ERROR';
export const SHOW_CHARTER_LOADING = 'SHOW_CHARTER_LOADING';
export const GET_CHARTER_DETAILS = 'GET_CHARTER_DETAILS';
export const CREATE_CHARTER_SUCCESS = 'CREATE_CHARTER_SUCCESS';
export const SHOW_EDIT_CHARTER_LOADING = 'SHOW_EDIT_CHARTER_LOADING';
export const EDIT_CHARTER_SUCCESS = 'EDIT_CHARTER_SUCCESS';
export const DELETE_CHARTER = 'DELETE_CHARTER';
export const MIGRATE_CHARTER_SUCCESS = 'MIGRATE_CHARTER_SUCCESS';

export const GET_ALLOWANCE = 'GET_ALLOWANCE';
export const ALLOWANCE_ERROR = 'ALLOWANCE_ERROR';
export const SHOW_ALLOWANCE_LOADING = 'SHOW_ALLOWANCE_LOADING';
export const GET_ALLOWANCE_DETAILS = 'GET_ALLOWANCE_DETAILS';
export const CREATE_ALLOWANCE_SUCCESS = 'CREATE_ALLOWANCE_SUCCESS';
export const SHOW_EDIT_ALLOWANCE_LOADING = 'SHOW_EDIT_ALLOWANCE_LOADING';
export const EDIT_ALLOWANCE_SUCCESS = 'EDIT_ALLOWANCE_SUCCESS';
export const DELETE_ALLOWANCE = 'DELETE_ALLOWANCE';
export const MIGRATE_ALLOWANCE_SUCCESS = 'MIGRATE_ALLOWANCE_SUCCESS';

export const GET_CONTRACT = 'GET_CONTRACT';
export const CONTRACT_ERROR = 'CONTRACT_ERROR';
export const SHOW_CONTRACT_LOADING = 'SHOW_CONTRACT_LOADING';
export const GET_CONTRACT_DETAILS = 'GET_CONTRACT_DETAILS';
export const CREATE_CONTRACT_SUCCESS = 'CREATE_CONTRACT_SUCCESS';
export const SHOW_EDIT_CONTRACT_LOADING = 'SHOW_EDIT_CONTRACT_LOADING';
export const EDIT_CONTRACT_SUCCESS = 'EDIT_CONTRACT_SUCCESS';
export const DELETE_CONTRACT = 'DELETE_CONTRACT';
export const MIGRATE_CONTRACT_SUCCESS = 'MIGRATE_CONTRACT_SUCCESS';
// Modification end - 09.22.2024 - maco-magsael-sc-689

// Modification start - 09.26.2024 - maco-magsael-sc-691
export const GET_TYSSALES = 'GET_TYSSALES';
export const GET_TYSSALES_DETAILS = 'GET_TYSSALES_DETAILS';
export const CREATE_TYSSALES = 'CREATE_TYSSALES';
export const DELETE_TYSSALES = 'DELETE_TYSSALES';
export const DELETE_TYSCLIENT = 'DELETE_TYSCLIENT';
export const TYSSALES_LOADING = 'TYSSALES_LOADING';
export const EDIT_TYSSALES = 'EDIT_TYSSALES';
export const SHOW_TYSSALES_LOADING = 'SHOW_TYSSALES_LOADING';
export const TYSSALES_ERROR = 'TYSSALES_ERROR';
export const GET_TYSCLIENTS = 'GET_TYSCLIENTS';
// Modification end - 09.26.2024 - maco-magsael-sc-691

// modification start - 10.07.2024 - maco-magsael-sc-692
export const GET_PAYROLL = 'GET_PAYROLL';
export const PAYROLL_ERROR = 'PAYROLL_ERROR';
export const SHOW_PAYROLL_LOADING = 'SHOW_PAYROLL_LOADING';
export const GET_PAYROLL_DETAILS = 'GET_PAYROLL_DETAILS';
export const CREATE_PAYROLL_SUCCESS = 'CREATE_PAYROLL_SUCCESS';
export const SHOW_EDIT_PAYROLL_LOADING = 'SHOW_EDIT_PAYROLL_LOADING';
export const EDIT_PAYROLL_SUCCESS = 'EDIT_PAYROLL_SUCCESS';
export const DELETE_PAYROLL = 'DELETE_PAYROLL';
export const MIGRATE_PAYROLL_SUCCESS = 'MIGRATE_PAYROLL_SUCCESS';
// modification end - 10.07.2024 - maco-magsael-sc-692

// Modification start - 10.16.2024 - maco-magsael-sc-695
export const EDIT_CERTIFICATE_LOADING = 'EDIT_CERTIFICATE_LOADING';
// Modification end - 10.16.2024 - maco-magsael-sc-695

// Modification start - 16.01.2025 - olain-tarcina-sc-721
export const GET_DOCDETAILS = 'GET_DOCDETAILS';
export const UPDATE_FILE_SUCCESS = 'UPDATE_FILE_SUCCESS';
// Modification end - 16.01.2025 - olain-tarcina-sc-721
// Modification start - 04.02.2025 - olain-tarcina-sc-734
export const DELETE_SHIPYARDJOB = 'DELETE_SHIPYARDJOB';
// Modification end - 04.02.2025 - olain-tarcina-sc-734
